import React from "react";
import Link from "next/link";

export default function Custom404() {
    return (
        <>
            <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
                <h1 className="mb-5">404 - Page Not Found</h1>
                <Link href="/">Go home</Link>
            </div>
        </>
    );
}
